import Image from "next/image";
import { useRouter } from "next/router";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import Heading from "atoms/Heading";
import Text from "atoms/Text";
import SidebarListItems from "../molecules/SidebarListItems";
import {
  getAuth,
  getCompanyId,
  getCurrentCompanySlug,
  setCompanyId,
  setCompanyTimeZone,
} from "services/identity.service";
import SelectCompanySlideOver from "./SelectCompanySlideOver";
import { getSignedUrl } from "services/aws-uploader.service";
import { useFlags } from "flagsmith/react";
import { roleDefaultPage } from "lib/utils";
import { getCompaniesByUserId, getUserByUserIdAndCompanyId } from "services/user.service";

const Sidebar = ({
  mainLogoSrc = "/images/f-logo-new.svg",
  mainLogoWidth = 56,
  mainLogoHeight = 17,
  mainLogoAlt = "",
  userDetails,
}) => {
  const showDesignationFlag =
    process.env.NEXT_PUBLIC_SHOW_DESIGNATION === "true";
  const flags = useFlags(["company_switch_route_change", "survey_templates",'company_theme']);
  const companySwitchRouteFlag = flags.company_switch_route_change;
  const showSurveyTemplates = flags.survey_templates.enabled;
  const myLoader = ({ src }) => src;
  const isSuperAdmin = userDetails?.roles?.some((role) => role.name.includes("Super Admin"));
  const router = useRouter();

  const goToRoute = (str) => (e) => {
    e.preventDefault();
    const companyId = getCompanyId();
    const slug = getCurrentCompanySlug(userDetails);
    router.push({
      pathname: `/[company]${str}`,
      query:
        str === "/admin/mytasks"
          ? { company: slug, tab: "pending" }
          : { company: slug },
    });
  };

  const [companies, setCompanies] = useState(userDetails?.company || []);

  useEffect(() => {
    (async () => {
      const response = await getCompaniesByUserId(userDetails.id);
      if (!response.status) {
        return;
      }

      const companiesWithSignedImages = response.entity?.company.map((a) => {
        return { ...a };
      });

      for (const element of companiesWithSignedImages) {
        element.logo =
          element.logo !== "" ? await getSignedUrl(element.logo) : "";
      }
      setCompanies(companiesWithSignedImages);
    })();
  }, []);

  const currentCompanyId = getCompanyId();
  const currentCompanyDetails =
    companies.length > 0
      ? companies.find((company) => company.id === currentCompanyId)
      : {};

  const [isSelectCompanySlideOver, setSelectCompanySlideOver] = useState(false);

  const onClickSuperAdmin = () => {
    setCompanyId(null);
    setCompanyTimeZone(null);
    setSelectCompanySlideOver(false);
    router.replace('/superadmin')
  }
  const onCompanySelect = async (company) => {
    setCompanyId(company.id);
    setCompanyTimeZone(company.timeZone);
    const companyWithRoles = companies.find((item) => item.id === company.id);
    const roles = companyWithRoles.userRoles.map((item) => item.Role);

    const routerPathObj = roleDefaultPage(roles, flags, userDetails, company);
    setSelectCompanySlideOver(false);
    router.push(routerPathObj);
  };

  const privileges = currentCompanyDetails?.userRoles
    ? currentCompanyDetails.userRoles.reduce((privileges, userRole) => {
      userRole.Role.privileges?.forEach(
        (privilege) => (privileges[privilege.slug] = true)
      );
      return privileges;
    }, {})
    : {};
  const getActiveMenu = (slug) => {
    return (
      router.pathname.includes(`/admin/${slug}`) || router.query.path === slug
    );
  };
  const showOnlyHeader = userDetails?.showOnlyHeader;
  return (
    <div
      className={`min-h-screen ${showOnlyHeader ? "" : "bg-white"
        } w-64 px-3 pt-6 pb-12 fixed left-0 top-0 z-40`}
    >
      <div
        className="flex justify-between items-center px-4 pb-6 mb-7 border-b border-gray-100 cursor-pointer"
        onClick={() => setSelectCompanySlideOver(true)}
      >
        <div className="flex items-center">
          <div className="flex items-center mr-2.5">
            <Image id='img_tag_126'
              loader={myLoader}
              src={currentCompanyDetails?.logo}
              width={38}
              height={38}
              alt={currentCompanyDetails?.companyName}
            />
          </div>
          <Heading type="h4">{currentCompanyDetails?.companyName}</Heading>
        </div>
        {!showOnlyHeader && (
          <div className="shrink-0 ml-2">
            <Image id='img_tag_127'
              src="/images/icons/downAngle-gray.svg"
              width="10"
              height="10"
              alt="Arrow"
            />
          </div>
        )}
      </div>
      {!showOnlyHeader && (
        <ul className="overflow-y-auto h-150/100vh">
          {(privileges.dashboard || privileges.reports) && (
            <SidebarListItems
              menuItem="Dashboard"
              menuIconNormalSrc="/images/icons/home.svg"
              menuIconHoverSrc="/images/icons/home-o.svg"
              menuIconAlt="Dashboard"
              menuActive={getActiveMenu("dashboard")}
              onClick={goToRoute("/admin/dashboard")}
            />
          )}
          {(privileges.tasks || privileges.all_tasks) && (
            <SidebarListItems
              menuItem="My Tasks"
              menuIconNormalSrc="/images/icons/calender-task.svg"
              menuIconHoverSrc="/images/icons/calender-task-o.svg"
              menuIconAlt="Candidates"
              menuActive={getActiveMenu("mytasks")}
              onClick={goToRoute("/admin/mytasks")}
            />
          )}
          {privileges.candidates && (
            <SidebarListItems
              menuItem="Candidates"
              menuIconNormalSrc="/images/icons/candidate-search.svg"
              menuIconHoverSrc="/images/icons/candidate-search-o.svg"
              menuIconAlt="Candidates"
              menuActive={getActiveMenu("candidates")}
              onClick={goToRoute("/admin/candidates")}
            />
          )}
          {privileges.positions && (
            <SidebarListItems
              menuItem="Positions"
              menuIconNormalSrc="/images/icons/chair.svg"
              menuIconHoverSrc="/images/icons/chair-o.svg"
              menuIconAlt="Positions"
              menuActive={getActiveMenu("positions")}
              onClick={goToRoute("/admin/positions")}
            />
          )}
          {privileges.campaign_templates && (
            <SidebarListItems
              menuItem="Campaign Templates"
              menuIconNormalSrc="/images/icons/speaker.svg"
              menuIconHoverSrc="/images/icons/speaker-o.svg"
              menuIconAlt="Campaign Templates"
              menuActive={getActiveMenu("campaign-templates")}
              onClick={goToRoute("/admin/campaign-templates")}
            />
          )}
          {privileges.designations && showDesignationFlag && (
            <SidebarListItems
              menuItem="Designations"
              menuIconNormalSrc="/images/icons/briefcase.svg"
              menuIconHoverSrc="/images/icons/briefcase-o.svg"
              menuIconAlt="Designations"
              menuActive={getActiveMenu("designations")}
              onClick={goToRoute("/admin/designations")}
            />
          )}
          {privileges.news_feed && (
            <SidebarListItems
              menuItem="News Feed"
              menuIconNormalSrc="/images/icons/globe-gray.svg"
              menuIconHoverSrc="/images/icons/globe-o.svg"
              menuIconAlt="News Feed"
              menuActive={getActiveMenu("news-feeds")}
              onClick={goToRoute("/admin/news-feeds")}
            />
          )}
          {privileges.html_templates && (
            <SidebarListItems
              menuItem="Html Templates"
              menuIconNormalSrc="/images/icons/code.svg"
              menuIconHoverSrc="/images/icons/code.svg"
              menuIconAlt="Html Templates"
              menuActive={getActiveMenu("html-templates")}
              onClick={goToRoute("/admin/html-templates")}
            />
          )}
          {privileges.survey_templates && (
            <SidebarListItems
              menuItem="Survey Templates"
              menuIconNormalSrc="/images/icons/survey.svg"
              menuIconHoverSrc="/images/icons/survey.svg"
              menuActive={getActiveMenu("survey-templates")}
              menuIconAlt="Survey Templates"
              onClick={goToRoute("/admin/survey-templates")}
            />
          )}
          {privileges.user_management && (
            <SidebarListItems
              menuItem="User Management"
              menuIconNormalSrc="/images/icons/users.svg"
              menuIconHoverSrc="/images/icons/users-o.svg"
              menuIconAlt="User Management"
              menuActive={getActiveMenu("users")}
              onClick={goToRoute("/admin/users")}
            />
          )}
           {privileges.user_management && flags.company_theme.enabled &&  (
            <SidebarListItems
              menuItem="Themes"
              menuIconNormalSrc="/images/icons/theme.svg"
              menuIconHoverSrc="/images/icons/theme-o.svg"
              menuIconAlt="Themes"
              menuActive={getActiveMenu("themes")}
              onClick={goToRoute("/admin/themes")}
            />
           )}
          {privileges.user_management && (
            <SidebarListItems
              menuItem="Settings"
              menuIconNormalSrc="/images/icons/setting.svg"
              menuIconHoverSrc="/images/icons/setting-o.svg"
              menuIconAlt="Settings"
              menuActive={getActiveMenu("settings")}
              onClick={goToRoute("/admin/settings")}
            />
          )}
        </ul>
      )}
      <div className="flex justify-center p-8 absolute bottom-0">
        <Text className="text-xxs shrink-0 mr-1"> Powered By</Text>
        <Image id='img_tag_128'
          loader={myLoader}
          src={mainLogoSrc}
          width={mainLogoWidth}
          height={mainLogoHeight}
          alt={mainLogoAlt}
        />
      </div>
      <SelectCompanySlideOver
        companyList={companies}
        isSuperAdmin={isSuperAdmin}
        onClickSuperAdmin={onClickSuperAdmin}
        isOpen={isSelectCompanySlideOver}
        onClose={() => setSelectCompanySlideOver(false)}
        onCompanySelect={onCompanySelect}
      />
    </div>
  );
};
export default Sidebar;

Sidebar.propTypes = {
  clientLogoSrc: PropTypes.string,
  clientLogoWidth: PropTypes.number,
  clientLogoHeight: PropTypes.number,
  clientLogoAlt: PropTypes.string,
  mainLogoSrc: PropTypes.string,
  mainLogoWidth: PropTypes.number,
  mainLogoHeight: PropTypes.number,
  mainLogoAlt: PropTypes.string,
  companyLists: PropTypes.array,
};
