import React from "react";
import PropTypes from "prop-types";
import Card from "atoms/Card";
import Image from "next/image";

const Modal = ({
  showModal = false,
  children,
  overLayBg = "bg-black ",
  overLayOpacity = "bg-opacity-20",
  cardClass = "",
  cardBg = "bg-white",
  cardBorder = "border",
  cardBorderColor = "border-gray-200",
  cardRadius = "rounded-2xl",
  cardPadding = "p-6",
  cardShadow = "shadow-md",
  CloseIconSrc = "/images/icons/close-black.svg",
  CloseIconWidth = 15,
  CloseIconHeight = 15,
  modalPosition = "justify-center",
  closeIconClass = "",
  closeModalPopup = () => {},
  isCloseIcon = true,
}) => {
  return (
    <>
      {showModal && (
        <div className={`fixed z-50 inset-0`}>
          <div className={`flex items-center min-h-screen ${modalPosition}`}>
            <div
              className={`fixed inset-0 ${overLayBg} ${overLayOpacity}`}
              onClick={closeModalPopup}
            ></div>
            <Card
              cardClass={`relative ${cardBg} ${cardBorder} ${cardBorderColor} ${cardRadius} ${cardPadding} ${cardShadow} ${cardClass} `}
            >
              <div
                className={`cursor-pointer absolute top-6 right-6 ${closeIconClass}`}
                onClick={closeModalPopup}
              >
                {isCloseIcon && (
                  <Image id='img_tag_26'
                    src={CloseIconSrc}
                    width={CloseIconWidth}
                    height={CloseIconHeight}
                    alt="close"
                  />
                )}
              </div>
              {children}
            </Card>
          </div>
        </div>
      )}
    </>
  );
};

export default Modal;

Modal.propTypes = {
  showModal: PropTypes.bool,
  overLayBg: PropTypes.string,
  overLayOpacity: PropTypes.string,
  children: PropTypes.node,
};
