import axios from "axios";
import { checkFor401Error, checkForTokenExpiredError } from "../lib/utils";
import {
  getAuth,
  getCompanyTimeZone,
  removeAuthOnInvalidToken,
} from "./identity.service";
import { refreshTokenAndSetAuth } from "./user.service";

export const postWithOutAuth = (url, entity) => {
  return new Promise((resolve, _reject) => {
    axios
      .post(url, entity)
      .then((response) => {
        if (response && response.data) {
          resolve(response.data);
        }
      })
      .catch((e) => {
        resolve({ status: false, message: e.message, error: e });
      });
  });
};

export const postWithAuth = (url, entity) => {
  return new Promise((resolve, _reject) => {
    const auth = getAuth();
    const headers = {
      "content-type": "application/json",
      "x-access-token": auth.token,
      "x-company-time-zone": getCompanyTimeZone(),
    };
    axios
      .post(url, entity, { headers })
      .then((response) => {
        if (response && response.data) {
          resolve(response.data);
        }
      })
      .catch((e) => {
        if (checkForTokenExpiredError(e)) {
          const callback = () => postWithAuth(url, entity);
          refreshTokenAndSetAuth(callback).then((data) => resolve(data));
          return;
        }
        if (checkFor401Error(e)) {
          return removeAuthOnInvalidToken();
        }
        resolve({ status: false, message: e.message, error: e });
      });
  });
};

export const getWithOutAuth = (url) => {
  return new Promise((resolve, _reject) => {
    axios
      .get(url)
      .then((response) => {
        if (response && response.data) {
          resolve(response.data);
        }
      })
      .catch((e) => {
        resolve({ status: false, message: e.message, error: e });
      });
  });
};

export const getWithAuth = (url) => {
  const auth = getAuth();
  const headers = {
    "content-type": "application/json",
    "x-access-token": auth.token,
    "x-company-time-zone": getCompanyTimeZone(),
  };
  return new Promise((resolve, _reject) => {
    axios
      .get(url, { headers })
      .then((response) => {
        if (response && response.data) {
          resolve(response.data);
        }
      })
      .catch((e) => {
        if (typeof window === "object") {
          if (checkForTokenExpiredError(e)) {
            const callback = () => getWithAuth(url);
            refreshTokenAndSetAuth(callback).then((data) => resolve(data));
            return;
          }
          if (checkFor401Error(e)) {
            return removeAuthOnInvalidToken();
          }
        }
        resolve({ status: false, message: e.message, error: e });
      });
  });
};

export const getWithAuthWithToken = (url) => {
  const auth = getAuth();

  const headers = {
    "content-type": "application/json",
    "x-access-token": auth.token,
    "x-company-time-zone": getCompanyTimeZone(),
  };
  return new Promise((resolve, _reject) => {
    axios
      .get(url, { headers })
      .then((response) => {
        if (response && response.data) {
          resolve(response.data);
        }
      })
      .catch((e) => {
        resolve({ status: false, message: e.message, error: e });
      });
  });
};

export const deleteWithAuth = (url, data) => {
  const auth = getAuth();
  const headers = {
    "content-type": "application/json",
    "x-access-token": auth.token,
  };

  return new Promise((resolve, _reject) => {
    axios
      .delete(url, { headers })
      .then((response) => {
        if (response && response.data) {
          resolve(response.data);
        }
      })
      .catch((e) => {
        if (checkForTokenExpiredError(e)) {
          const callback = () => deleteWithAuth(url, entity);
          refreshTokenAndSetAuth(callback).then((data) => resolve(data));
          return;
        }
        if (checkFor401Error(e)) {
          return removeAuthOnInvalidToken();
        }
        resolve({ status: false, message: e.message, error: e });
      });
  });
};

export const putWithAuth = (url, data) => {
  const auth = getAuth();
  const headers = {
    "content-type": "application/json",
    "x-access-token": auth.token,
    "x-company-time-zone": getCompanyTimeZone(),
  };
  return new Promise((resolve, _reject) => {
    axios
      .put(url, data, { headers })
      .then((response) => {
        if (response && response.data) {
          resolve(response.data);
        }
      })
      .catch((e) => {
        if (checkForTokenExpiredError(e)) {
          const callback = () => putWithAuth(url, data);
          refreshTokenAndSetAuth(callback).then((data) => resolve(data));
          return;
        }
        if (checkFor401Error(e)) {
          return removeAuthOnInvalidToken();
        }
        resolve({ status: false, message: e.message, error: e });
      });
  });
};

export const putWithOutAuth = (url, entity) => {
  return new Promise((resolve, _reject) => {
    axios
      .put(url, entity)
      .then((response) => {
        if (response && response.data) {
          resolve(response.data);
        }
      })
      .catch((e) => {
        resolve({ status: false, message: e.message, error: e });
      });
  });
};

export const sendBeacon = (url, data) => {
  var params = new URLSearchParams(data);

  return navigator.sendBeacon(url, params);
};
