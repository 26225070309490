const moment = require("moment");
import chroma from 'chroma-js';
import * as R from "ramda";

const capitalizeFirstLetter = (string) => {
  return string ? string.charAt(0).toUpperCase() + string.slice(1) : "";
};

const formatDate = (date, format = "DD MMM YYYY", defaultReturnValue = "-") => {
  return date ? moment(date).format(format) : defaultReturnValue;
};

export const getExecutionDate = (task, tasksList) => {
  const format = "DD MMM YYYY";
  const exeDate = formatDate(
    new Date(task.executionDate).setHours(9, 0, 0, 0),
    "dddd, DD MMM YYYY hh:mm A"
  );
  if (task.type !== "meeting") {
    return exeDate;
  }
  if (R.isNil(task.prevTaskExeDate) && R.isNil(task.nextTaskExeDate)) {
    return exeDate;
  }
  const startDate = task.prevTaskExeDate
    ? moment(task.prevTaskExeDate).add(1, "days").format(format)
    : formatDate(task.executionDate);
  const endDate = task.nextTaskExeDate
    ? moment(task.nextTaskExeDate).subtract(1, "days").format(format)
    : formatDate(task.executionDate);
  if (moment(startDate).isSameOrAfter(moment(endDate))) {
    return formatDate(exeDate, "dddd, DD MMM YYYY hh:mm A");
  }
  return `${startDate} - ${endDate}`;
};

const throwAPIError = ({ error }) => {
  if (error?.response.status === 401) {
    throw error.data.message;
  }
};

export const checkFor401Error = (error) => {
  const { status } = error.response;
  return status === 401;
};

export const checkForTokenExpiredError = (error) => {
  const { status, data } = error.response;
  return status === 401 && data.message === "Token Expired!";
};

export const getDateDifference = ({ dateOne, dateTwo, diffIn = "days" }) => {
  return Math.max(moment(dateOne).diff(moment(dateTwo), diffIn), 0);
};

const getDateRange = (timeFrame) => {
  let set = { hour: 0, minute: 0, second: 0, millisecond: 0 };
  let format = "YYYY-MM-DD HH:mm:ss";
  return R.cond([
    [
      R.equals("lastWeek"),
      () => ({
        fromDate: moment().subtract(7, "days").set(set).format(format),
        toDate: moment().set(set).format(format),
      }),
    ],
    [
      R.equals("last15days"),
      () => ({
        fromDate: moment().subtract(15, "days").set(set).format(format),
        toDate: moment().set(set).format(format),
      }),
    ],
    [
      R.equals("lastMonth"),
      () => ({
        fromDate: moment().subtract(1, "month").set(set).format(format),
        toDate: moment().set(set).format(format),
      }),
    ],
    [
      R.equals("last90days"),
      () => ({
        fromDate: moment().subtract(90, "days").set(set).format(format),
        toDate: moment().set(set).format(format),
      }),
    ],
    [
      R.equals("lastSixMonths"),
      () => ({
        fromDate: moment()
          .subtract(5, "month")
          .startOf("month")
          .set(set)
          .format(format),
        toDate: moment().set(set).format(format),
      }),
    ],
    [
      R.equals("custom"),
      () => ({
        fromDate: moment(timeFrame.dates[0]).set(set).format(format),
        toDate: moment(timeFrame.dates[1]).set(set).format(format),
      }),
    ],
  ])(timeFrame.type);
};
export { capitalizeFirstLetter, formatDate, throwAPIError, getDateRange };

export const getWhatsAppBody = (type) => {
  if (type === "content") {
    return `Dear {{1}},
    We have something exciting for you to check out on our ANSR Loop platform, as we get closer to you joining us at {{2}}.
    
    To view {{3}}, sign in to your ANSR Loop account ({{4}}). Keep an eye out for further interesting information.
    
    Cheers to reading!
    Team {{5}}`;
  } else if (type === "meeting") {
    return `Dear {{1}},
    We have a meeting set up for you with {{2}} on {{3}} as your joining date approaches.
    For further information, check your ANSR Loop account({{4}}) after logging in.
    
    We’re looking forward to meeting you!
    Team {{5}}`;
  } else if (type === "give_away" || type == "survey") {
    return `Dear {{1}}
    We have a surprise for you on the way as your joining date approaches! For further information, check your ANSR Loop account ({{2}}) after logging in.
    
    Team {{3}}`;
  }
};

export const getUTCDateWithTime = (date, [h, m, s]) => {
  const mo = moment(date);
  mo.set("hour", h);
  mo.set("minute", m);
  mo.set("second", s);
  return mo.utc().toDate();
};

export const snakeToCamel = (str) => {
  return str.replace(/(?!^)_(.)/g, (_, char) => char.toUpperCase());
};

export const removeNotSelectedFilterData = (filters) => {
  Object.keys(filters).forEach((key) => {
    if (R.isNil(filters[key].value) || R.isEmpty(filters[key].value)) {
      delete filters[key];
    } else if (
      R.is(Object, filters[key].value) &&
      R.hasPath(["fromDate"], filters[key].value) &&
      R.isNil(filters[key].value.fromDate)
    ) {
      delete filters[key];
    } else if (
      R.is(Object, filters[key].value) &&
      R.hasPath(["min"], filters[key].value) &&
      R.isNil(filters[key].value.min) &&
      R.isNil(filters[key].value.max)
    ) {
      delete filters[key];
    }
  });
  return filters;
};

export const getSelectedFilters = (filters) => {
  const newFilters = { ...filters };
  const customFilters = newFilters.customAttributeFilters || {};
  const selectedFilterData = {
    ...removeNotSelectedFilterData(newFilters),
    customAttributeFilters: {
      ...removeNotSelectedFilterData(customFilters),
    },
  };
  if (R.isEmpty(selectedFilterData.customAttributeFilters)) {
    delete selectedFilterData["customAttributeFilters"];
  }
  return selectedFilterData;
};

export const getInitialFilterData = (preSelectedFilters) => {
  return {
    timeframe: {
      label: "Time Frame",
      type: "date",
      value: {},
    },
    touchPointType: {
      label: "Touchpoint Type",
      type: "single select",
      value: preSelectedFilters?.touchPointType,
    },
    recruiters: {
      label: "Recruiters",
      type: "multi select",
      value: preSelectedFilters.recruiters ? preSelectedFilters.recruiters : [],
    },
    hiringManagers: {
      label: "Hiring Managers",
      type: "multi select",
      value: preSelectedFilters.hiringManagers
        ? preSelectedFilters.hiringManagers
        : [],
    },
    campaignTemplates: {
      label: "Campaign Templates",
      type: "multi select",
      value: preSelectedFilters.campaignTemplates
        ? preSelectedFilters.campaignTemplates
        : [],
    },
    joiningDate: {
      label: "Joining Date",
      type: "date",
      value: {
        fromDate: null,
        toDate: null,
      },
    },
    executionDate: {
      label: "Execution Date",
      type: "date",
      value: {
        fromDate: null,
        toDate: null,
      },
    },
    experience: {
      label: "Experience",
      type: "integer",
      value: preSelectedFilters.experience
        ? preSelectedFilters.experience
        : null,
    },
    status: {
      label: "Status",
      type: "single select",
      value: preSelectedFilters?.status,
    },
    engagementLevel: {
      label: "Engagement Level",
      type: "single select",
      value: preSelectedFilters?.engagementLevel,
    },
    taskErrorType: {
      label: "Failed Type",
      type: "single select",
      value: preSelectedFilters?.taskErrorType,
    },
    failedTaskStatus: {
      label: "Status",
      type: "single select",
      value: preSelectedFilters?.failedTaskStatus,
    },
    userRole: { label: "Roles", value: "", type: "single select" },
    company: { label: "Company", value: null, type: "single select" },
    country: { label: "Country", value: null, type: "single select" },
    companyAdmins: { label: "Company Admin", value: [], type: "multi select" },
  };
};

export const getBody = (touchPoint) => {
  return touchPoint.bodyType === "html_template" && touchPoint.bodyHtmlTemplate
    ? touchPoint.bodyHtmlTemplate.html
    : touchPoint.body;
};

export const getBodyFromHtmlTemplate = (touchPoint, htmlTemplates) => {
  if (touchPoint.bodyType === "html_template") {
    const found = htmlTemplates.find(
      (htmlTemplate) => htmlTemplate.value == touchPoint.bodyHtmlTemplateId
    );
    return found ? found.html : touchPoint.body;
  } else {
    return touchPoint.body;
  }
};

export const roleDefaultPage = (roles, flags, user, company) => {
  const baseUrl = "/[company]/admin";
  if (
    roles.find((role) =>
      [
        "Leadership",
        "Company Admin",
        "Recruiter Manager",
        "Talent Acquisition Head",
        "HR Ops",
      ].includes(role.name)
    )
  ) {
    return {
      pathname: `${baseUrl}/dashboard`,
      query: { company: company.slug },
    };
  }
  if (roles.find((role) => role.name === "Recruiter")) {
    return {
      pathname: `${baseUrl}/mytasks`,
      query: { company: company.slug, tab: "pending" },
    };
  }
  if (roles.find((role) => role.name === "Hiring Manager")) {
    return {
      pathname: `${baseUrl}/positions`,
      query: { company: company.slug },
    };
  }
  if (roles.find((role) => role.name === "Campaign Admin")) {
    return {
      pathname: `${baseUrl}/campaign-templates`,
      query: { company: company.slug },
    };
  }
  if (roles.find((role) => role.name === "Newsfeed Admin")) {
    return {
      pathname: `${baseUrl}/news-feeds`,
      query: { company: company.slug },
    };
  }
  if (roles.find((role) => role.name === "Report Admin")) {
    return {
      pathname: `${baseUrl}/dashboard`,
      query: { company: company.slug },
    };
  }
};

export const getCustomFilterObjects = (
  config,
  selectedCustomFilters,
  handleCustomAttributeChange
) => {
  const type = config.type;
  switch (type) {
    case "text":
      return {
        type: "text",
        isFilterSelected:
          !!selectedCustomFilters[config.id].value &&
          selectedCustomFilters[config.id].value !== "",
        options: getCustomFilterOptions(
          type,
          config,
          selectedCustomFilters,
          handleCustomAttributeChange
        ),
      };
    case "integer":
    case "double":
      return {
        type: "radio",
        isFilterSelected:
          !!selectedCustomFilters[config.id].value &&
          selectedCustomFilters[config.id].value?.type !== "all",
        options: getCustomFilterOptions(
          type,
          config,
          selectedCustomFilters,
          handleCustomAttributeChange
        ),
      };
    case "single select":
    case "multi select":
      return {
        type: "checkbox",
        isFilterSelected:
          selectedCustomFilters[config.id].value &&
          selectedCustomFilters[config.id].value.length > 0,
        show: config.options.length > 1,
        options: getCustomFilterOptions(
          type,
          config,
          selectedCustomFilters,
          handleCustomAttributeChange
        ),
      };
    case "date":
      return {
        type: "daterange",
        isFilterSelected:
          selectedCustomFilters[config.id].value &&
          !!(
            selectedCustomFilters[config.id].value.fromDate &&
            selectedCustomFilters[config.id].value.toDate
          ),
        dateRangeProps: getCustomFilterOptions(
          type,
          config,
          selectedCustomFilters,
          handleCustomAttributeChange
        ),
      };
    case "boolean":
      return {
        type: "radio",
        isFilterSelected: !!selectedCustomFilters[config.id].value,
        options: getCustomFilterOptions(
          type,
          config,
          selectedCustomFilters,
          handleCustomAttributeChange
        ),
      };
  }
};
const getCustomFilterOptions = (
  type,
  config,
  selectedCustomFilters,
  handleCustomAttributeChange
) => {
  switch (type) {
    case "text":
      return [
        {
          id: config.id,
          name: config.name,
          value: selectedCustomFilters[config.id].value,
          isSelected: !!selectedCustomFilters[config.id].value,
          custom: true,
          handleChange: (e) => {
            handleCustomAttributeChange(config, e);
          },
        },
      ];
    case "integer":
    case "double":
      const labels = [
        "All",
        "Greater Than",
        "Lesser Than",
        "In Between",
        "Equal to",
      ];
      const options = [
        "all",
        "greaterThan",
        "lesserThan",
        "inBetween",
        "equalTo",
      ];
      if (type === "double") {
        options = options.filter((option) => option !== "equalTo");
      }
      return options.map((option, index) => ({
        id: option,
        name: option,
        value: option,
        isSelected: selectedCustomFilters[config.id].value?.type === option,
        handleChange: (e) => handleCustomAttributeChange(config, e),
        label: labels[index],
        custom: option !== "all",
        customConfig:
          option === "all"
            ? {}
            : option !== "inBetween"
              ? {
                inputType: "number",
                placeholder: "Enter Number",
                type: "textField",
                value:
                  option === "greaterThan" || option === "equalTo"
                    ? selectedCustomFilters[config.id].value?.min
                    : selectedCustomFilters[config.id].value?.max,
                onChange: (e) => {
                  handleCustomAttributeChange(config, e, option);
                },
              }
              : {
                minPlaceHolder: "Start",
                maxPlaceHolder: "End",
                inputType: "number",
                type: "rangeTextField",
                min: selectedCustomFilters[config.id].value?.min,
                max: selectedCustomFilters[config.id].value?.max,
                onChange: (e) => handleCustomAttributeChange(config, e, option),
              },
      }));

    case "single select":
    case "multi select":
      return config.options.map((option) => ({
        id: option.value,
        name: config.id,
        value: option.value,
        isSelected:
          selectedCustomFilters[config.id].value &&
          selectedCustomFilters[config.id].value.includes(option.value),
        handleChange: (e) => handleCustomAttributeChange(config, e),
        label: option.value,
      }));
    case "date":
      return {
        onDateChange: (e) => handleCustomAttributeChange(config, e),
        dateRange: [
          selectedCustomFilters[config.id].value.fromDate,
          selectedCustomFilters[config.id].value.toDate,
        ],
      };
    case "boolean":
      return ["true", "false"].map((option) => ({
        id: option,
        name: option,
        value: option,
        isSelected: selectedCustomFilters[config.id].value === option,
        handleChange: (e) => handleCustomAttributeChange(config, e),
        label: option === "true" ? "Yes" : "No",
      }));
  }
};

export const removeUndefinedValues = (data) => {
  const dataWithOutUndefined = {};
  Object.keys(data).forEach((key) => {
    if (data[key] !== undefined) {
      dataWithOutUndefined[key] = data[key];
    }
  });
  return dataWithOutUndefined;
};

export const getCompanyDetails = (currentUser) => {
  const companyId = getCompanyId();
  const company = currentUser.company.find(
    (company) => company.id === companyId
  );
  return company;
};

export const removeCustomAttributeEmptyValues = (data) => {
  const dataWithOutEmptyValues = {};
  data && Object.keys(data).forEach((key) => {
    if (data[key] !== undefined && data[key] !== "" && data[key] !== null) {
      dataWithOutEmptyValues[key] = data[key];
    }
  });
  return dataWithOutEmptyValues;
};

export const computeAndSetPrimaryShades = async ({ document, color }) => {
  const primary50 = chroma(color).brighten(0.8).hex();
  const primary100 = chroma(color).brighten(0.6).hex();
  const primary300 = chroma(color).brighten(0.4).hex();
  const primary400 = chroma(color).brighten(0.2).hex();
  const primary500 = chroma(color).hex();
  const primary600 = chroma(color).darken(0.2).hex();
  const primary700 = chroma(color).darken(0.6).hex();
  const primary900 = chroma(color).darken(1).hex();
  document.documentElement.style.setProperty('--primary-color-50', primary50);
  document.documentElement.style.setProperty('--primary-color-100', primary100);
  document.documentElement.style.setProperty('--primary-color-300', primary300);
  document.documentElement.style.setProperty('--primary-color-400', primary400);
  document.documentElement.style.setProperty('--primary-color-500', primary500);
  document.documentElement.style.setProperty('--primary-color-600', primary600);
  document.documentElement.style.setProperty('--primary-color-700', primary700);
  document.documentElement.style.setProperty('--primary-color-900', primary900);
}

export const getEmojiSrc = (circleValue) => {
  if (circleValue === 'disengaged') return '/images/icons/sad-emoji-ac.svg'
  if (circleValue === 'low' || circleValue === 'negative') return '/images/icons/negative.svg'
  if (circleValue === 'medium' || circleValue === 'neutral') return '/images/icons/neutral.svg'
  if (circleValue === 'high' || circleValue === 'positive') return '/images/icons/positive.svg'
  else {
    if (circleValue > 67) return '/images/icons/love-emoji-ac.svg'
    if (circleValue > 33 && circleValue <= 66) return '/images/icons/happy-emoji-ac.svg'
    return '/images/icons/sad-emoji-ac.svg'
  }
}
export const getEmojiText = (circleValue) => {
  if (Number.isInteger(Number(circleValue)) || circleValue === 'disengaged') {

    if (circleValue > 67) return 'Positive'
    if (circleValue > 33 && circleValue <= 66) return 'Neutral'
    return 'Negative'
  }
  if (circleValue === 'low') return 'Negative'
  if (circleValue === 'medium') return 'Neutral'
  if (circleValue === 'high') return 'Positive'
  return circleValue
}

export const getCustomFedexCss = () => {
  const originUrl = window.location.origin;
  return `
  @font-face {
      font-family: 'FedEx Sans';
      src: url('${originUrl}/fonts/FedExSans_Rg.ttf') format('truetype');
    }
    @font-face {
      font-family: 'FedEx Sans Light';
      src: url('${originUrl}/fonts/FedExSans_lt.ttf') format('truetype');
    }
    * {
      font-family: 'FedEx Sans' !important;
    }

    h1, h2, h3, h4 {
      font-family: 'FedEx Sans Light' !important;
    }`;
  }

  export const isValidToRun = (task) => {
    const invalidTokens = [
      "CANDIDATE_NAME",
      "POSITION_TITLE",
      "DOJ",
      "COMPANY_NAME",
      "RECRUITER_NAME",
      "HIRING_MANAGER_NAME",
      "COMPANY_ADDRESS",
      "CANDIDATE_ADDRESS",
      "TOUCHPOINT_TITLE",
    ];
    const validPlaceHolders = task.placeholders.filter(
      (field) => !invalidTokens.includes(field)
    );
    const isValid = validPlaceHolders.reduce((accumulator, currentValue) => {
      return (
        accumulator && task[snakeToCamel(currentValue.toLowerCase())] !== null
      );
    }, true);
    return isValid;
  };
