import { Dialog, Transition } from "@headlessui/react";
import PropTypes from "prop-types";
import { Fragment } from "react";

const SlideOver = ({
  panelWidth,
  children,
  isOpen,
  onClose,
  contentPosition = "right-0",
  enterFrom = "translate-x-full",
  leaveTo = "translate-x-full",
  isOverlay = true,
  contentClass = "",
}) => {
  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-40" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div
            className={` ${
              isOverlay &&
              "fixed inset-0 bg-black bg-opacity-50 transition-opacity"
            }`}
          />
        </Transition.Child>

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div
              className={`pointer-events-none fixed inset-y-0 flex max-w-full ${contentPosition}`}
            >
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom={enterFrom}
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo={leaveTo}
              >
                <Dialog.Panel
                  className={`pointer-events-auto relative w-screen ${panelWidth}`}
                >
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-500"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-500"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute top-0 left-0 -ml-8 flex pt-4 pr-2 sm:-ml-10 sm:pr-4"></div>
                  </Transition.Child>
                  <div
                    className={`flex h-full flex-col bg-white shadow-xl ${contentClass}`}
                  >
                    {children}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default SlideOver;

SlideOver.propTypes = {
  contentPosition: PropTypes.string,
  enterFrom: PropTypes.string,
  leaveTo: PropTypes.string,
  panelWidth: PropTypes.string,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  contentClass: PropTypes.string,
};
