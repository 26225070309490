import { Menu, MenuButton, MenuItem } from "@szhsin/react-menu";
import Image from "next/image";
import PropTypes from "prop-types";
import Avatar from "atoms/Avatar";
import Heading from "atoms/Heading";
import Text from "atoms/Text";
import { useRouter } from "next/router";
import { useFlags } from "flagsmith/react";

const Header = ({
  headerImgSrc = "/images/logo-orange.svg",
  headerImgWidth = 119,
  headerImgHeight = 23,
  headerImgAlt,
  avatarSrc = "/images/default-profile.svg",
  onLogOut,
  isLogoAvail = true,
  containerWidth = "container",
  className = "",
  padding = "py-3 md:py-5",
  userDetails,
  isCandidate,
  isTitleAvail = false,
  title = "",
  isPreview
}) => {

  const myLoader = ({ src }) => src;
  const flag = useFlags(["whatsapp_consent", "company_theme"]);
  const router = useRouter();
  return (
    <header
      className={`${!isPreview && 'fixed top-0  left-0'} w-full bg-white shadow-normal z-30 ${className} ${padding} ${isCandidate && userDetails?.company?.theme?.headerType==='custom' && flag.company_theme.enabled && 'bg-primary-500'}`}
    >
      <div className={`mx-auto px-4 ${containerWidth}`}>
        <div className="flex justify-between items-center">
          {isLogoAvail && (
            <Image id='img_tag_95'
              loader={myLoader}
              onClick={() => {
                isCandidate &&
                  router.push({
                    pathname: "/[company]/dashboard",
                    query: {
                      company: userDetails.company.slug,
                    },
                  });
              }}
              className="cursor-pointer shadow-lg"
              src={`${headerImgSrc}`}
              width={`${headerImgWidth}`}
              height={`${headerImgHeight}`}
              alt={`${headerImgAlt}`}
            />
          )}
          {isTitleAvail && <Heading type="h4">{title}</Heading>}

          <Menu
            align={"end"}
            menuButton={
              <MenuButton className="ml-auto">
                <div className="flex items-center">
                  <Avatar
                    avatarSrc={avatarSrc}
                    avatarWidth={40}
                    avatarHeight={40}
                  ></Avatar>
                  <div className="ml-2">
                    <Image id='img_tag_96'
                      src="/images/icons/downAngle.svg"
                      width="10"
                      height="10"
                      alt="Arrow"
                    />
                  </div>
                </div>
              </MenuButton>
            }
          >
            <MenuItem className="w-60  cursor-default">
              <div className="p-4 h-full w-full bg-gray-50 border border-gray-100 relative">
                {flag.whatsapp_consent.enabled && isCandidate && (
                  <div
                    className="absolute right-0 top-0 p-2 cursor-pointer"
                    onClick={() => {
                      router.push({
                        pathname: "/[company]/settings",
                        query: { company: userDetails.company.slug },
                      });
                    }}
                  >
                    <Image id='img_tag_97'
                      src="/images/icons/setting.svg"
                      width="25"
                      height="25"
                      alt="Arrow"
                      className="cursor-pointer"
                    />
                  </div>
                )}

                <Heading type="h6" className="text-base">
                  {userDetails && userDetails.name}
                </Heading>
                <Text className="text-xs text-gray-400">
                  {userDetails && userDetails.emailId}
                </Text>
              </div>
            </MenuItem>
            <MenuItem className="w-60">
              <div
                className="flex p-4 text-sm text-gray-600"
                onClick={onLogOut}
              >
                <Image id='img_tag_98'
                  src="/images/icons/logout.svg"
                  width="20"
                  height="20"
                  alt="edit"
                />
                <span className="ml-2.5">Sign out</span>
              </div>
            </MenuItem>
          </Menu>
        </div>
      </div>
    </header>
  );
};

export default Header;
Header.propTypes = {
  headerImgSrc: PropTypes.string,
  headerImgWidth: PropTypes.number,
  headerImgHeight: PropTypes.number,
  headerImgAlt: PropTypes.string,
  AvatarSrc: PropTypes.string,
  isLogoAvail: PropTypes.bool,
};
