import Image from "next/image";
import { useRouter } from "next/router";
import PropTypes from "prop-types";
import Text from "atoms/Text";
import SidebarListItems from "../molecules/SidebarListItems";
import SelectCompanySlideOver from './SelectCompanySlideOver';
import { setCompanyId, setCompanyTimeZone } from 'services/identity.service';
import { useEffect, useState } from 'react';
import { getSignedUrl } from 'services/aws-uploader.service';
import { roleDefaultPage } from 'lib/utils';
import { getCompaniesByUserId } from "services/user.service";

const SuperAdminSidebar = ({
  mainLogoSrc = "/images/f-logo-new.svg",
  mainLogoWidth = 56,
  mainLogoHeight = 17,
  mainLogoAlt = "",
  userDetails
}) => {
  const myLoader = ({ src }) => src;

  const router = useRouter();

  const goToRoute = (str) => (e) => {
    e.preventDefault();
    router.push(str);
  };
  const [companies, setCompanies] = useState([]);

  useEffect(() => {
    (async () => {
      const response = await getCompaniesByUserId(userDetails.id);
      if (!response.status) {
        return;
      }
      const companiesWithSignedImages = response.entity?.company.map((a) => {
        return { ...a };
      });

      for (const element of companiesWithSignedImages) {
        element.logo =
          element.logo !== "" ? await getSignedUrl(element.logo) : "";
      }
      setCompanies(companiesWithSignedImages);
    })();
  }, []);
  const [isSelectCompanySlideOver, setSelectCompanySlideOver] = useState(false);

  const onClickSuperAdmin = () => {
    setCompanyId(null);
    setCompanyTimeZone(null);
    setSelectCompanySlideOver(false);
    router.replace('/superadmin')
  }
  const onCompanySelect = async (company) => {
    setCompanyId(company.id);
    setCompanyTimeZone(company.timeZone);
    const roles = companies.find((item) => item.id === company.id).userRoles.map((item) => item.Role);
    const routerPathObj = roleDefaultPage(roles,null,  userDetails, company);
    setSelectCompanySlideOver(false);
    router.push(routerPathObj);
  };


  return (
    <div className="min-h-screen bg-white w-64 px-3 pt-6 pb-12 fixed left-0 top-0 z-40">
      <div className="flex justify-between items-center px-4 pb-6 mb-7 border-b border-gray-100 cursor-pointer"  onClick={() => setSelectCompanySlideOver(true)}>
        <div className="flex items-center">
          <div className="flex items-center mr-2.5">
            <Image id='img_tag_121'
              loader={myLoader}
              src="/images/logo-black.png"
              width={170}
              height={57}
              alt={"ANSR Loop"}
            />
             <div className="shrink-0 ml-2">
            <Image id='img_tag_122'
              src="/images/icons/downAngle-gray.svg"
              width="10"
              height="10"
              alt="Arrow"
            />
          </div>
          </div>
        </div>
      </div>
      <ul>
        <SidebarListItems
          menuItem="Companies"
          menuIconNormalSrc="/images/icons/building-outline.svg"
          menuIconHoverSrc="/images/icons/building-outline-o.svg"
          menuIconAlt="Companies"
          menuActive={router.pathname.startsWith("/superadmin/companies")}
          onClick={goToRoute("/superadmin/companies")}
        />
        <SidebarListItems
          menuItem="Users & Roles"
          menuIconNormalSrc="/images/icons/users.svg"
          menuIconHoverSrc="/images/icons/users-o.svg"
          menuIconAlt="Users & Roles"
          menuActive={router.pathname.startsWith("/superadmin/users")}
          onClick={goToRoute("/superadmin/users")}
        />
      </ul>
      <div className="flex justify-center p-8 absolute bottom-0">
        <Text className="text-xxs shrink-0 mr-1"> Powered By</Text>
        <Image id='img_tag_123'
          loader={myLoader}
          src={mainLogoSrc}
          width={mainLogoWidth}
          height={mainLogoHeight}
          alt={mainLogoAlt}
        />
      </div>
      <SelectCompanySlideOver
        companyList={companies}
      //  isSuperAdmin={isSuperAdmin}
        onClickSuperAdmin={onClickSuperAdmin}
        isOpen={isSelectCompanySlideOver}
        onClose={() => setSelectCompanySlideOver(false)}
        onCompanySelect={onCompanySelect}
      />
    </div>
  );
};
export default SuperAdminSidebar;

SuperAdminSidebar.propTypes = {
  mainLogoSrc: PropTypes.string,
  mainLogoWidth: PropTypes.number,
  mainLogoHeight: PropTypes.number,
  mainLogoAlt: PropTypes.string,
};
