import EmptyLayout from "./EmptyLayout";
import Layout from "./Layout";
const layoutConfig = [
  {
    path: "/admin/login",
    layout: EmptyLayout,
  },
  {
    path: "/login",
    layout: EmptyLayout,
  },
  // {
  //   path: "/[company]/admin/schedule/[userId]",
  //   layout: EmptyLayout,
  // },
  {
    path: "",
    layout: EmptyLayout,
  },
];

const getLayout = (path) => {
  let config = layoutConfig.find((conf) => path == conf.path);
  if (config) return config.layout;
  else if (path.includes("admin")) return Layout;
  else return EmptyLayout;
};

export { getLayout };
