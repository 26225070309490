import Cookies from "universal-cookie";
import Router from 'next/router'
import { useContext } from 'react';
import { SessionContext } from 'components/SessionContext';

const cookies = new Cookies();

export const getAuth = () => {
  const auth = cookies.get("AUTH");
  return auth;
};

export const setAuth = (authObject) => {
  cookies.set("AUTH", JSON.stringify(authObject), { path: "/" });
  return authObject;
};

export const getCompanyId = () => {
  const companyId = cookies.get("COMPANY_ID");
  return companyId;
};

export const setCompanyId = (companyId) => {
  cookies.set("COMPANY_ID", JSON.stringify(companyId), { path: "/" });
  return companyId;
};

export const getCompanyTimeZone = () => {
  const timeZone = cookies.get("COMPANY_TIME_ZONE");
  return timeZone === undefined ? null : timeZone;
};

export const setCompanyTimeZone = (timeZone) => {
  cookies.set("COMPANY_TIME_ZONE", JSON.stringify(timeZone), { path: "/" });
  return timeZone;
};

export const removeAuth = () => {
  cookies.remove("COMPANY_ID", { path: "/" });
  cookies.remove("AUTH", { path: "/" });
  return;
};

export const isInRole = (role, user) => {
  return user.roles && user.roles.includes(role);
};

export const isAuthenticated = (user) => {
  return user != null && user.token;
};

export const isUnauthorizedRequest = (auth) => {
  return !auth || !isAuthenticated(JSON.parse(auth));
};

export const addRequestCookiesToCache = (requestCookies) => {
  const auth = JSON.parse(requestCookies.AUTH);
  setAuth(auth);

  const companyId = JSON.parse(requestCookies.COMPANY_ID);
  setCompanyId(companyId);

  const timeZone = requestCookies.COMPANY_TIME_ZONE ? JSON.parse(requestCookies.COMPANY_TIME_ZONE) : "";
  setCompanyTimeZone(timeZone);
}

export const removeAuthAndRedirect = (res) => {
  res.setHeader("Set-Cookie", `AUTH=deleted; Max-Age=0;path=/;`);
  return { redirect: { destination: "/admin/login", permanent: false } };
}

export const removeAuthOnInvalidToken = () => {
  removeAuth();
  Router.push('/admin/login');
}



export const currentUserHasRole = (role) => {
  const userDetails = useContext(SessionContext);
  const companyId = getCompanyId();
  const currentCompany = userDetails?.company.find(
    (company) => company.id === companyId
  );
  return currentCompany?.userRoles?.some(
    (userRole) => userRole.Role.name === role
  );
}
export const getCurrentCompanySlug = (userDetails) => {
  const companyId = getCompanyId();
  const currentCompany = userDetails.company.find((company) => company.id === companyId)
  const slug = currentCompany.slug;
  return slug;
}
export const isCurrentUserLeadership = () => {
  return currentUserHasRole('Leadership')
}


export const isCurrentUserCompanyAdmin = () => {
  return currentUserHasRole('Company Admin')
}

export const isCurrentUserHROps = () => {
  return currentUserHasRole('HR Ops');
}
