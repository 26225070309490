import Image from "next/image";
import PropTypes from "prop-types";

const Button = ({
  type = "",
  children,
  variant,
  fontWeight = "font-medium",
  padding = "py-3 px-6",
  btnClass = "",
  imgClassName = "",
  imgSrc,
  imgWidth,
  imgHeight,
  imgAlt = "",
  onClick = () => {},
  disabled,
  form,
  buttonRef,
  leadingImg = true
}) => {
  const buttonType = {
    primaryBtn: "primaryBtn",
    outlineBtn: "outlineBtn",
    normalBtn: "normalBtn",
    outlineBtnPrimary: "outlineBtnPrimary",
  };

  const buttonStyle = {
    primaryBtn:
      "flex items-center justify-center bg-primary-600 rounded text-white text-base disabled:bg-primary-100 disabled:text-primary-300",
    outlineBtn:
      "flex items-center justify-center bg-transparent border border-gray-200 rounded text-gray-500 text-base drop-shadow-btn disabled:bg-primary-100 disabled:text-primary-300",
    normalBtn:
      "flex items-center justify-center text-base disabled:border-primary-300 disabled:text-primary-300",
    outlineBtnPrimary:
      "flex items-center justify-center bg-transparent border border-primary-600 rounded text-primary-600 text-base drop-shadow-btn disabled:bg-primary-100 disabled:text-primary-300",
  };

  return (
    <button
      type={type}
      variant={buttonType[variant]}
      className={`${
        buttonStyle[variant]
      } ${fontWeight} ${padding} ${btnClass} ${imgSrc && "pl-4"}`}
      onClick={onClick}
      disabled={disabled}
      form={form}
      ref={buttonRef}
    >
      {leadingImg && imgSrc ? (
        <span
          className={`mx-2 flex items-center flex-shrink-0 ${imgClassName}`}
        >
          <Image
            id="img_tag_2"
            src={imgSrc}
            width={imgWidth}
            height={imgHeight}
            alt={imgAlt}
          />
        </span>
      ) : (
        ""
      )}
      {children}
      {!leadingImg && imgSrc ? (
        <span
          className={`mx-2 flex items-center flex-shrink-0 ${imgClassName}`}
        >
          <Image
            id="img_tag_2"
            src={imgSrc}
            width={imgWidth}
            height={imgHeight}
            alt={imgAlt}
          />
        </span>
      ) : (
        ""
      )}
    </button>
  );
};
export default Button;

Button.propTypes = {
  type: PropTypes.string,
  variant: PropTypes.string,
  className: PropTypes.string,
  fontWeight: PropTypes.string,
  padding: PropTypes.string,
  btnClass: PropTypes.string,
  imgClassName: PropTypes.string,
  imgSrc: PropTypes.string,
  imgWidth: PropTypes.number,
  imgHeight: PropTypes.number,
  imgAlt: PropTypes.string,
  children: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};
