import * as AWS from 'aws-sdk';
import axios from 'axios';
import * as HttpService from './http.service';
import {
     GET_UPLOAD_PRE_SIGNED_URL, GET_SIGNED_URL
} from './url.service';

const bucketUrl = process.env.NEXT_PUBLIC_BUCKET_URL;


export const getSignedUrl = (fileKey) => new Promise((resolve) => {
    HttpService.getWithAuthWithToken(GET_SIGNED_URL(fileKey))
        .then((response) => {
            if (response?.status) {
                resolve(response.entity);
            }
            resolve(``);
        });
});

export const getSignedUrlWithoutAuth = (fileKey) => new Promise((resolve) => {
    HttpService.getWithOutAuth(GET_SIGNED_URL(fileKey))
        .then((response) => {
            console.log(response);
            if (response?.status) {
                resolve(response.entity);
            }
            resolve(``);
        });
});





export const getMediaUrl = (fileKey) => bucketUrl + fileKey;

export const uploadImageToS3Bucket = (fileKey, file) => new Promise((resolve, reject) => {
    HttpService.postWithAuth(GET_UPLOAD_PRE_SIGNED_URL, { fileKey })
        .then(async (mediaKey) => {
            const formData = new FormData();

            for (const key in mediaKey.entity.fields) {
                if (Object.hasOwnProperty.call(mediaKey.entity.fields, key)) {
                    const element = mediaKey.entity.fields[key];
                    formData.append(key, element);
                }
            }

            formData.append('acl', 'private');
            formData.append('Content-Type', file.type);
            formData.append('file', file);

            return axios({
                method: 'post',
                url: mediaKey.entity.url,
                data: formData,
                headers: { 'Content-Type': file.type }
            });
        })
        .then((res) => {
            const mediaUrl = getMediaUrl(fileKey);
            resolve({ url: mediaUrl });
        })
        .catch((err) => {
            reject(err);
        });
});
