import Header from "organisms/Header";
import React, { useState } from "react";

import Sidebar from "organisms/Sidebar";
import SuperAdminSidebar from "organisms/SuperAdminSidebar";
import { getCompanyId, removeAuth } from "services/identity.service";
import { useRouter } from "next/router";
import WarningModal from "organisms/WarningModal";
import Head from "next/head";
import CompanyHeader from "organisms/CompanyHeader";

const Layout = (props) => {
  const router = useRouter();
  const companyId = getCompanyId();
  const [openWarningModal, setOpenWarningModal] = useState(false);
  const handleLogOut = () => {
    removeAuth();
    router.push("/admin/login");
  };
  const userDetails = props.children.props.currentUserDetails;
  const isSuperAdmin = userDetails?.roles.some((role) => role.name === "Super Admin") && companyId === 'null';

  const showOnlyHeader = userDetails?.showOnlyHeader;
  return (
    <React.Fragment>
      <div className="flex bg-gray-50 overflow-hidden">
        <Head>
          <link rel="icon" href="/images/favicon.ico" />
          <title>
            {isSuperAdmin ? "ANSR Loop - Super Admin" : "ANSR Loop - Admin"}
          </title>
        </Head>
        {isSuperAdmin ? (
          <SuperAdminSidebar userDetails={userDetails} />
        ) : (
          userDetails &&
          !showOnlyHeader && <Sidebar userDetails={userDetails} />
        )}
        <div className="w-full pl-64">
          <CompanyHeader
            userDetails={userDetails}
            isLogoAvail={showOnlyHeader}
            onLogOut={() => setOpenWarningModal(true)}
          />
          <div className="min-h-screen w-full overflow-y-auto pt-28 pr-8 pb-15 pl-6">
            {props.children}
            <WarningModal
              modalWidth="max-w-xl"
              modalInnerWidth="w-full"
              modalInnerAlignment="text-left"
              modalHeading="Are you sure you want to sign out?"
              modalButtonAlign="justify-end mt-6"
              primaryBtnText="Yes"
              outlineBtnText="No"
              showModal={openWarningModal}
              closeModalPopup={() => setOpenWarningModal(false)}
              onClick={handleLogOut}
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Layout;
