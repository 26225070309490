import React from "react";
import PropTypes from "prop-types";
import Head from "next/head";

const EmptyLayout = ({ children }) => {
  return (
    <>
      <Head>
        <link rel="icon" href="/images/favicon.ico" />
        <title>ANSR Loop - Candidate</title>
      </Head>
      {children}
    </>
  );
};

EmptyLayout.propTypes = {
  children: PropTypes.node,
};

export default EmptyLayout;
